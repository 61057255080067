import { useState, useMemo, useEffect } from 'react'
import {
  Stack,
  Grid,
  PaginationControls,
  Box,
  Text,
  useProductsQuery,
  usePagination,
  Container,
  Heading,
  Skeleton,
  Flex,
  Checkbox,
  Button,
  Graphic,
  LinkButton,
  SelectInput,
  IconButton,
  useBodyScrollLock,
  CloseButton,
  useTheme,
  useBackendQuery,
} from '@thirstycamel/ui'
import { useDebounce } from 'use-debounce/lib'
import range from 'lodash.range'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import type { CoreProductEntity } from '@ts/core/src/modules/coreproduct/coreproduct.entity'
import type { CoreProductSortOptions } from '@ts/core/src/modules/coreproduct/coreproduct.service'

import { useActions, useStore } from '../../store/hooks'
import ProductCard, { ProductCardPlaceholder } from '../ProductCard'
import { useMediaQueryContext } from '../MediaQueryProvider'
import { useOver18 } from '../../hooks/useOver18'
import type CategoryEntity from '@ts/core/src/modules/category/category.entity'
import ProductListEntity from '@ts/core/src/modules/productlist/productlist.entity'

import ReactMarkdown from 'react-markdown'
import './markdown-styles.css'

interface SearchOptionsListProps {
  selector?: string
  displayField?: string
  label?: string
  options: any[]
  parentKey?: string
  value?: string[]
  isLoading: boolean
  isButtons?: boolean
  onChange: (value: string[]) => void
}

const formatHeading = string =>
  string
    ?.replace(/-/g, ' ')
    .split(' ')
    .map(str => `${str?.[0]?.toUpperCase()}${str?.slice(1)}`)
    .join(' ')

const SORT_OPTIONS: { label: string; value: keyof typeof CoreProductSortOptions }[] = [
  { label: 'Price', value: 'PRODUCT_PRICE' },
  { label: 'Brand', value: 'BRAND_NAME' },
  // { label: 'Newest', value: 'PRODUCT_UPDATED_AT' },
]

const SEARCH_SORT_OPTIONS: { label: string; value: keyof typeof CoreProductSortOptions }[] = [
  {
    label: 'Relevance',
    value: 'RELEVANCE',
  },
]

const MOBILE_SEARCH_SORT_OPTIONS = [
  {
    label: 'Relevance',
    value: 'RELEVANCE|1',
  },
]

const SORT_OPTIONS_MOBILE = [
  { label: 'Cheapest First', value: 'PRODUCT_PRICE|1' },
  { label: 'Most Expensive First', value: 'PRODUCT_PRICE|-1' },
  { label: 'Brand (A-Z)', value: 'BRAND_NAME|1' },
  { label: 'Brand (Z-A)', value: 'BRAND_NAME|-1' },
]

const PRICE_OPTIONS = [
  {
    id: 'under-10',
    name: 'UNDER $10',
    max: 1000,
  },
  {
    id: '10-15',
    name: '$10-$15',
    max: 1500,
    min: 1000,
  },
  {
    id: '15-25',
    name: '$15-$25',
    max: 2500,
    min: 1500,
  },
  {
    id: '25-50',
    name: '$25-$50',
    max: 5000,
    min: 2500,
  },
  {
    id: '50-100',
    name: '$50-$100',
    max: 10000,
    min: 5000,
  },
  {
    id: 'over-100',
    name: '$100 PLUS',
    min: 10000,
  },
]

const SearchOptionsList: React.FC<SearchOptionsListProps> = ({
  selector = 'id',
  displayField = 'name',
  label,
  parentKey = '',
  options,
  isLoading,
  value = [],
  isButtons = false,
  onChange,
}) => {
  const handleCheck = id => {
    if (!value.includes(id)) {
      onChange([...value, id])
    } else {
      onChange(value.filter(v => v !== id))
    }
  }

  return (
    <Stack spacing={4}>
      {label && (
        <Stack isInline>
          <Heading as="h4" fontSize="xs">
            {label}
          </Heading>
        </Stack>
      )}

      <Stack>
        {!options || isLoading ? (
          Array.from(new Array(3)).map(o => <Skeleton h="1rem" w="6rem" />)
        ) : (
          <Grid gridTemplateColumns={isButtons ? '1fr 1fr' : ''} gridGap={2}>
            {options.map((o, i) =>
              isButtons ? (
                <Button
                  key={`${parentKey}${o[selector]}` || o.id}
                  variant="unstyled"
                  bg={
                    value && value.includes(`${parentKey}${o[selector]}`) ? 'pink.500' : 'gray.50'
                  }
                  color={
                    value && value.includes(`${parentKey}${o[selector]}`) ? 'white' : 'pink.500'
                  }
                  fontFamily="heading"
                  flexGrow={1}
                  textTransform="uppercase"
                  px={3}
                  minHeight={8}
                  border="2px"
                  onClick={() => handleCheck(`${parentKey}${o[selector]}`)}
                  borderColor={
                    value && value.includes(`${parentKey}${o[selector]}`)
                      ? 'pink.500'
                      : 'transparent'
                  }
                  borderRadius="lg"
                  fontSize="xs"
                  _hover={{ border: '2px', borderColor: 'pink.500' }}
                  _focus={{ border: '2px', borderColor: 'pink.500', boxShadow: 'outline' }}
                >
                  {o.name}
                </Button>
              ) : (
                <Stack isInline spacing={2}>
                  <Checkbox
                    isChecked={value.includes(`${parentKey}${o[selector]}`)}
                    onChangeValue={() => handleCheck(`${parentKey}${o[selector]}`)}
                  >
                    <Text key={`${parentKey}${o[selector]}`} fontSize={['sm', 'md', 'xs']}>
                      {o[displayField].toUpperCase()}&nbsp;
                      {o.count != null && (
                        <Box as="span" color="gray.500">
                          ({o.count})
                        </Box>
                      )}
                    </Text>
                  </Checkbox>
                </Stack>
              ),
            )}
          </Grid>
        )}
      </Stack>
    </Stack>
  )
}

const getDefaultFromQuery = (key, query) => {
  return (query[key] as string)?.split(',').filter(x => x) || []
}

const useFilterUrl = (name: string, filter: string | string[]) => {
  const router = useRouter()

  const [isFirstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if (isFirstLoad) {
      setFirstLoad(false)
      return
    }

    const value =
      filter?.length > 0 ? (typeof filter === 'string' ? filter : filter.join(',')) : null

    const query = {
      ...router.query,
      [name]: value,
    }

    /* We don't want these 2 fields to appear in the query. */
    delete query.group
    delete query.category

    /* Remove any fields that are empty */
    Object.keys(query).forEach(key => {
      if (!query[key]) delete query[key]
    })

    let asPath = `/${router.query.group || 'search'}`
    if (router.query?.category?.[0]) asPath += `/${router.query.category[0]}`

    router.replace(
      { pathname: router.pathname, query },
      { pathname: asPath, query },
      { shallow: true },
    )
  }, [filter])
}

export type SearchResultsProps = {
  search?: string
  category?: string
  group?: string
  special?: boolean
  totals?: boolean
  price?: string
  barcodes?: string
  hideFilters?: boolean
  facetByCategory?: boolean
  seo_description?: string
}

export const SearchResults: React.FC<SearchResultsProps> = ({
  totals,
  hideFilters,
  facetByCategory,
  seo_description,
}) => {
  const theme = useTheme()
  const router = useRouter()

  const { selectedStore } = useStore(s => s.store)
  const location = useStore(store => store.location)

  const isSpecial = router.query.group === 'specials'
  const isBundles = router.query.group === 'bundles'
  const isEveryday = router.query.group === 'everyday-value'

  const group = isSpecial || isEveryday ? null : (router.query.group as string)
  const category = router.query.category as string

  const defaultBarcodes = getDefaultFromQuery('barcodes', router.query)
  const defaultBrands = getDefaultFromQuery('brands', router.query)
  const defaultTypes = getDefaultFromQuery('types', router.query)
  const defaultCategories = getDefaultFromQuery('categories', router.query)
  const defaultGroups = getDefaultFromQuery('groups', router.query).filter(x => x !== 'specials')

  if (group && !defaultGroups?.includes(group) && !isSpecial) defaultGroups.push(group)
  if (category && !defaultCategories?.includes(category))
    defaultCategories.push(`${group}:${category}`)

  const search = router.query.search as string
  const defaultPrice = router.query.price as string

  const [types, setTypes] = useState<string[]>(defaultTypes)
  const [brands, setBrands] = useState<string[]>(defaultBrands)
  const [groups, setGroups] = useState<string[]>(defaultGroups)
  const [categories, setCategories] = useState<string[]>(defaultCategories)
  const [barcodes, setBarcodes] = useState<string[]>(defaultBarcodes)

  const [isFirstLoad, setFirstLoad] = useState(true)
  // const [priceFilter, setPriceFilter] = useState<string | null>(price)

  const [page, setPage] = useState(1)
  const [itemsPerPage] = useState(12)
  const [sort, setSort] = useState(search ? 'RELEVANCE' : 'PRODUCT_PRICE')
  const [order, setOrder] = useState(1)
  const [isFiltersVisible, setFiltersVisible] = useState(false)

  useBodyScrollLock(isFiltersVisible)

  useFilterUrl('groups', groups)
  useFilterUrl('types', types)
  useFilterUrl('brands', brands)

  // useEffect(() => {
  //   setPriceFilter(price)
  // }, [price])

  const mediaQuery = useMediaQueryContext()

  const numberOfPlaceholders = mediaQuery.isMobile
    ? 2
    : mediaQuery.isTablet
    ? 4
    : mediaQuery.isSmallDesktop
    ? 2
    : mediaQuery.isMediumDesktop
    ? 3
    : 4

  const isSearch = !!search

  let productOptions: any = {
    group: !isBundles ? group : undefined,
  }

  if (!selectedStore) {
    productOptions.region = location.region
  } else if (selectedStore) {
    productOptions.store = selectedStore.slug
  }

  const products = useProductsQuery<CoreProductEntity>({
    page,
    itemsPerPage,
    search,
    categories: categories?.length > 0 ? categories : types.length ? types : undefined,
    brands: !isBundles && brands.length ? brands : undefined,
    groups: !isBundles && groups.length ? groups : undefined,
    type: isBundles ? 'BUNDLE' : undefined,
    // minPrice: priceFilter ? PRICE_OPTIONS.find(p => p.id === priceFilter)?.min : undefined,
    // maxPrice: priceFilter ? PRICE_OPTIONS.find(p => p.id === priceFilter)?.max : undefined,
    special: isSpecial,
    everydayValue: isEveryday,
    totals,
    barcodes: barcodes.length > 0 ? barcodes.join(',') : undefined,
    bundleGroup: router.query?.bundleGroup,
    sort,
    order: order === 1 ? 'ASC' : 'DESC',
    facetByCategory,
    ...productOptions,
  })

  const isOver18 = useOver18()
  const openModal = useActions(s => s.modal.showModal)

  const categoryQuery = useBackendQuery<CategoryEntity>([`categories/${category}`], {
    enabled: !!category,
    onSuccess: c => {
      if (c.over18Only && !isOver18) {
        openModal({ name: 'OVER_18_REQUIRED' })
      }
    },
  })

  useEffect(() => {
    if (products?.latestData) {
      setFirstLoad(false)
    }
  }, [products?.latestData])

  const totalPages = products.resolvedData?.results?.meta?.totalPages || 1
  const totalProducts = products.resolvedData?.results?.meta?.totalItems || 0

  const pagination = usePagination({
    totalPages,
    page,
    setPage,
  })

  const hasProducts = products?.latestData?.results?.items?.length > 0
  const hasResolvedProducts = products?.resolvedData?.results?.items?.length > 0
  const [isFetching] = useDebounce(products?.isFetching, 300)

  const handlePageChange = () => {
    window.scrollTo(0, 0)
  }

  const region = selectedStore ? selectedStore?.region : location.region

  const productListQuery = useBackendQuery<ProductListEntity>([
    `product-lists/slug/${group}${region ? '?region=' + region : ''}`,
  ])

  const _category = formatHeading(category?.[0])
  const _group = formatHeading(group)

  const isShowingPlaceholders = !hasProducts && (!hasResolvedProducts || isFetching)

  const getTypes = (group?: string) => {
    if (group) {
      return products?.resolvedData?.categories.filter(category => category.groupId === group)
    }

    return products?.resolvedData?.categories
  }

  const groupsToDisplay = groups?.length
    ? products?.resolvedData?.groups?.filter(
        ({ slug }) => groups.includes(slug) || productListQuery?.data,
      )
    : products?.resolvedData?.groups

  const _hideFilters = hideFilters || isBundles

  useEffect(() => {
    productListQuery.refetch()
  }, [region])

  return (
    <Box py={8} bg="gray.50">
      <Container size="xl" display="flex">
        {!_hideFilters && (
          <Box
            w={['100vw', , '220px', , '250px']}
            h={['100vh', , 'auto']}
            mr={[, , 6]}
            position={['fixed', , 'relative']}
            right={['100%', , 'auto']}
            top={[0, , 'auto']}
            transform={[isFiltersVisible ? 'translate(100%)' : null, , 'none']}
            transition="250ms ease transform"
            zIndex={[theme.zIndices.overlay, , 1]}
            flexShrink={0}
          >
            <Flex
              border="2px"
              borderColor="gray.100"
              borderRadius={[, , 'lg']}
              bg="white"
              minHeight="500px"
              h={['100%', , 'auto']}
              direction="column"
              justify="space-between"
            >
              <Box overflowY={['auto', , 'hidden']}>
                <Stack spacing={0}>
                  <Stack
                    isInline
                    align="center"
                    justify="space-between"
                    p={5}
                    borderBottomWidth={2}
                    borderBottomColor="gray.100"
                  >
                    <Heading fontSize={18}>FILTER RESULTS</Heading>

                    <CloseButton
                      onClick={() => setFiltersVisible(false)}
                      display={['flex', , 'none']}
                    />
                  </Stack>

                  <Box
                    px={5}
                    py={5}
                    borderBottomWidth={2}
                    borderBottomColor="gray.100"
                    display={[, , 'none']}
                  >
                    <SearchOptionsList
                      label="SORT"
                      selector="value"
                      displayField="label"
                      value={[`${sort}|${order}`]}
                      onChange={values => {
                        /* Get the last element of the array (the item we just clicked on).
                         * Split it by the | to obtain the sort & order, and set them both. */
                        const value = values?.[values.length - 1]
                        if (!value) return
                        const [_sort, _order] = value.split('|')
                        setSort(_sort)
                        setOrder(parseInt(_order, 10))
                      }}
                      options={[
                        ...(search ? MOBILE_SEARCH_SORT_OPTIONS : []),
                        ...SORT_OPTIONS_MOBILE,
                      ]}
                      isLoading={false}
                    />
                  </Box>

                  {products?.resolvedData?.groups?.length && !group && (
                    <Box px={5} py={5} borderBottomWidth={2} borderBottomColor="gray.100">
                      <SearchOptionsList
                        label="CATEGORY"
                        selector="slug"
                        onChange={value => {
                          setGroups(value)

                          const newTypes = types
                            .map(t => products?.resolvedData?.categories.find(c => c.id === t))
                            .filter(t => t && value.includes(t.groupId))
                            .map(({ id }) => id)

                          setTypes(newTypes)

                          setPage(1)
                        }}
                        value={groups}
                        options={products?.resolvedData?.groups}
                        isLoading={isFirstLoad && products?.isFetching}
                      />
                    </Box>
                  )}

                  {products?.resolvedData?.categories?.length && !categories?.length && (
                    <Box px={5} py={5} borderBottomWidth={2} borderBottomColor="gray.100">
                      <Stack spacing={8}>
                        {groupsToDisplay.map(g => (
                          <Stack>
                            <Heading as="h4" fontSize="xs" textTransform="uppercase">
                              {g.name}
                            </Heading>
                            <SearchOptionsList
                              selector="slug"
                              parentKey={`${g.slug}:`}
                              onChange={value => {
                                setTypes(value)
                                setPage(1)
                              }}
                              value={types}
                              options={getTypes(g.id)}
                              isLoading={isFirstLoad && products?.isFetching}
                            />
                          </Stack>
                        ))}
                      </Stack>
                    </Box>
                  )}

                  {/* <Box px={5} py={5} borderBottomWidth={2} borderBottomColor="gray.100">
                  <SearchOptionsList
                    label="PRICE"
                    onChange={value => {
                      console.log(value)
                      if (value.length) {
                        setPriceFilter(value[value.length - 1])
                      } else {
                        setPriceFilter(null)
                      }
                      setPage(1)
                    }}
                    value={priceFilter ? [priceFilter] : []}
                    isButtons
                    options={PRICE_OPTIONS}
                    isLoading={isFirstLoad && products?.isFetching}
                  />
                </Box> */}

                  {products?.resolvedData?.brands?.length && (
                    <Box px={5} py={5}>
                      <SearchOptionsList
                        label="BRAND"
                        onChange={value => {
                          setBrands(value)
                          setPage(1)
                        }}
                        value={brands}
                        options={products?.resolvedData?.brands}
                        isLoading={isFirstLoad && products?.isFetching}
                      />
                    </Box>
                  )}
                </Stack>
              </Box>

              <Box px={8} py={6} borderTop="1px" borderColor="gray.200" display={[, , 'none']}>
                <Button
                  isRound
                  variantColor="pink"
                  onClick={() => setFiltersVisible(false)}
                  w="100%"
                >
                  Show {totalProducts} result{totalProducts !== 1 ? 's' : ''}{' '}
                </Button>
              </Box>
            </Flex>
          </Box>
        )}

        <Box w="100%">
          <Stack mb={5} isInline justify="space-between" align={['flex-end', 'center']}>
            {isSearch ? (
              <Heading textAlign={['center', , 'left']} isTruncated>
                {' '}
                {search ? (
                  <>
                    <Skeleton isLoaded={!isFetching} display="inline">
                      {totalProducts}
                    </Skeleton>
                    &nbsp;result{totalProducts !== 1 ? 's' : ''} for '{search}'
                  </>
                ) : (
                  'Search results'
                )}
              </Heading>
            ) : (
              <Stack spacing={0}>
                {_category && (
                  <Heading as="h5" fontSize="xs" textTransform="uppercase" color="pink.500">
                    {_group}
                  </Heading>
                )}

                <Flex align={[, , , 'center']} direction={['column', , , 'row']}>
                  {!productListQuery?.data?.title && (
                    <Heading textTransform="uppercase" fontSize={['2xl', '3xl']}>
                      {_category || _group}{' '}
                      {isSpecial ? 'SPECIALS' : isEveryday ? 'Everyday Value' : ''}{' '}
                      {barcodes && !isSpecial && !isEveryday && !_category && !_group
                        ? 'MATCHING PRODUCTS'
                        : ''}
                    </Heading>
                  )}

                  <Text fontSize="lg" color="blackAlpha.700">
                    {!productListQuery?.data?.title && (
                      <Box display={['none', , , 'inline']}>&nbsp;-&nbsp;</Box>
                    )}
                    <Skeleton isLoaded={!isFetching} display="inline">
                      {totalProducts} result{totalProducts !== 1 ? 's' : ''}
                    </Skeleton>
                  </Text>
                </Flex>
              </Stack>
            )}

            <Flex display={['flex', , 'none']} flexShrink={0} pl={4} pb={3}>
              <Button isRound size="sm" variantColor="pink" onClick={() => setFiltersVisible(true)}>
                Filters
              </Button>
            </Flex>

            <Flex display={['none', , 'flex']} align="center" flexShrink={0}>
              <SelectInput
                options={[...(search ? SEARCH_SORT_OPTIONS : []), ...SORT_OPTIONS]}
                value={sort}
                onChangeValue={value => {
                  if (value === 'RELEVANCE') {
                    setOrder(1)
                  }
                  setSort(value)
                }}
                background="transparent"
                border={0}
                dir="rtl"
                mr={1}
              />

              <IconButton
                icon="arrow-down"
                size="sm"
                isRound
                fontSize="2xl"
                variantColor="pink"
                isDisabled={sort === 'RELEVANCE'}
                transform={order === -1 ? 'rotate(180deg)' : null}
                onClick={() => setOrder(state => state * -1)}
              />
            </Flex>
          </Stack>

          {hasProducts || hasResolvedProducts || products?.isFetching ? (
            <Box w="100%">
              <Grid
                gap={[6, 4]}
                w="100%"
                templateColumns={[
                  'repeat(1, 1fr)',
                  'repeat(2, 1fr)',
                  ,
                  'repeat(3, 1fr)',
                  'repeat(4, 1fr)',
                ]}
              >
                {isShowingPlaceholders
                  ? range(numberOfPlaceholders).map(() => <ProductCardPlaceholder w="100%" />)
                  : products.resolvedData?.results.items.map(product => {
                      return <ProductCard product={product} key={product?.id} h="100%" />
                    })}
              </Grid>

              <Flex mt={6} justify="center">
                <Skeleton isLoaded={!isShowingPlaceholders}>
                  <PaginationControls
                    pagination={pagination}
                    totalPages={totalPages}
                    isFetching={products.isFetching}
                    onPageChange={handlePageChange}
                  />
                </Skeleton>
              </Flex>
              <Box className="markdown-content" mt={8} p={4} borderRadius="md" textAlign="center">
                <ReactMarkdown>{seo_description}</ReactMarkdown>
              </Box>
            </Box>
          ) : products.error ? (
            <Box>
              <Text>An error has occurred.</Text>
            </Box>
          ) : (
            <Flex
              textAlign="center"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              py={10}
            >
              <Graphic mx="auto" name="camel" size={['10rem', '15rem']} mb={10} opacity={0.4} />
              <Heading>
                It looks like we don’t have that option available.
                <br />
                Let's find you another form of hydration.
              </Heading>
              <LinkButton href="/" hrefAs="/" mt={10} variantColor="pink">
                Back to home
              </LinkButton>
            </Flex>
          )}
        </Box>
      </Container>
    </Box>
  )
}
